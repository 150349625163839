<template>
  <div>
    <v-container fluid grid-list-md class="Container_Tabela py-3">
      <v-row align="center" justify="center" text-xs-center>
        <v-expansion-panels
          :key="panelKey"
          accordion
          focusable
          multiple
          v-model="painel"
          v-if="!iniciandoPainel">
          <v-expansion-panel
            v-for="categoria in listaCategoriasCopia"
            :key="categoria.id">
            <v-expansion-panel-header>
              <v-row no-gutters>
                <h3 class="my-auto">{{ categoria.nomeCategoriaAgrupada }}</h3>
                <v-spacer/>
                <planejamento-contrato-form-acoes-controle-popover-copia-categoria
                  :key="`copia-${categoria.id}`"
                  v-if="false && podeCopiar"
                  :message="$t('message.selecione_categorias_para_clonar',
                    { codCategoria: categoria.idExterno, nomeCategoria: categoria.nomeCategoria })"
                  @agree="copiaCategoria"
                  icone="library_add"
                  :lista-categorias="listaCategorias"
                  :categoria="categoria"
                  :textoAcao="$t('label.copiar_percentuais_categoria')">
                </planejamento-contrato-form-acoes-controle-popover-copia-categoria>
                <v-tooltip
                  bottom
                  v-if="podeRemoverCategoria && !somenteLeitura">
                  <template v-slot:activator="{ on }">
                    <v-btn icon
                      v-on="on"
                      @click.stop="confirmaRemoverCategoria(categoria)">
                      <v-icon color="red">delete</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('label.cancelar') }}</span>
                </v-tooltip>
              </v-row>
            </v-expansion-panel-header>
            <v-expansion-panel-content ripple>
              <v-container fluid grid-list-md class="Container_Tabela py-3">
                <v-row>
                  <v-col cols="8">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on: tooltip }">
                        <v-btn
                          v-on="{ ...tooltip }"
                          @click="abrirModalCategoriaFornecedor(categoria)"
                          class="mr-3 mb-3"
                          id="btn_prod_forn"
                          color="primary">
                          {{ $t('label.visualizar_categorias_fornecedores') }}
                        </v-btn>
                      </template>
                    </v-tooltip>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" :md="isTipoContratoSamsung || isTipoContratoNivelFamilia || isTipoContratoEpoca ? 12 : 9">
                    <v-data-table
                      class="elevation-1"
                      :headers="cabecalho"
                      :items="categoria.acoes"
                      hide-default-footer
                      disable-pagination
                      :items-per-page="-1"
                      :no-data-text="$t('label.tabela_sem_conteudo')"
                      :key="chave"
                      >
                      <template
                        v-slot:header.calculo_apuracao="{ header }">
                        <span class="nowrap">
                          {{ header.text }}
                          <tooltip-ajuda color="black" :mensagem="`${$t('message.ajuda.planejamento_contrato.configuracao_apuracao')}`" />
                        </span>
                      </template>
                      <template v-slot:body="{ items }">
                        <tbody>
                          <tr v-for="acao in items" :key="`acao-${categoria.id}-${acao.idAcaoTipo}-${acao.idCopia}`">
                            <td v-if="isTipoContratoSamsung || isTipoContratoNivelFamilia || isTipoContratoEpoca">
                              <v-tooltip bottom v-if="acao.indAtivarDuplicidade">
                                <template v-slot:activator="{ on }">
                                  <button @click="copiarLinhaAcao(acao, categoria.acoes)" v-if="!acao.idCopia && isCadastro(acao)" v-on="on">
                                    <v-icon>content_copy</v-icon>
                                  </button>
                                </template>
                                <span>{{ $t('label.duplicar_acao') }}</span>
                              </v-tooltip>
                              <button @click="removerCopiaConfirm(acao, categoria.acoes)" v-if="acao.idCopia && isCadastro(acao)">
                                <v-icon>delete</v-icon>
                              </button>
                            </td>
                            <td>
                              <span>{{ acao.nomeAcaoTipo }}</span>
                            </td>
                            <td v-if="isTipoContratoSamsung || isTipoContratoNivelFamilia || isTipoContratoEpoca ">
                              <v-form :id="`form-periodo-negociacao-${categoria.id}-${acao.idAcaoTipo}`"
                                :ref="`form-periodo-negociacao-${categoria.id}-${acao.idAcaoTipo}`" lazy-validation autocomplete="off">
                                <tr>
                                  <td>
                                    <v-menu
                                      :close-on-content-click="false"
                                      v-model="acao.pickerDataInicio"
                                      :id="`data-inicio-${categoria.id}-${acao.idAcaoTipo}`"
                                      name="data_inicio"
                                      :disabled="!isCadastro(acao)"
                                      :nudge-right="40"
                                      transition="scale-transition"
                                      offset-y
                                      max-width="290px"
                                      min-width="290px">
                                      <template v-slot:activator="{ on }">
                                        <v-text-field
                                          v-on="on"
                                          class="mt-2 pt-2"
                                          hide-details
                                          readonly
                                          :clearable="isCadastro(acao)"
                                          :disabled="!isCadastro(acao)"
                                          :rules="rules.dateValidation(acao, 'dtaInicioFormatada')"
                                          v-model="acao.dtaInicioFormatada"
                                          :label="`${$t('label.data_inicio_cont')} *`">
                                        </v-text-field>
                                      </template>
                                      <v-date-picker
                                        locale="pt-br"
                                        color="primary"
                                        :min="objetoPassoGeralContrato.dtaVigenciaInicio"
                                        :max="objetoPassoGeralContrato.dtaVigenciaFim"
                                        v-model="acao.dtaInicio"
                                        @change="acao.indAcaoAlterada = true"
                                        @input="setaDataInicioFormatada(acao)" />
                                    </v-menu>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <v-menu
                                      :close-on-content-click="false"
                                      :id="`data-fim-${categoria.id}-${acao.idAcaoTipo}`"
                                      name="data_fim"
                                      v-model="acao.pickerDataFim"
                                      :disabled="!isCadastro(acao)"
                                      :nudge-right="40"
                                      transition="scale-transition"
                                      offset-y
                                      max-width="290px"
                                      min-width="290px">
                                      <template v-slot:activator="{ on }">
                                        <v-text-field
                                          v-on="on"
                                          :return-masked-value="true"
                                          class="mt-2 pt-2"
                                          hide-details
                                          readonly
                                          :clearable="isCadastro(acao)"
                                          :disabled="!isCadastro(acao)"
                                          :rules="rules.dateValidation(acao, 'dtaFimFormatada')"
                                          v-model="acao.dtaFimFormatada"
                                          :label="`${$t('label.data_fim_cont')} *`">
                                        </v-text-field>
                                      </template>
                                      <v-date-picker
                                        locale="pt-br"
                                        color="primary"
                                        scrollable
                                        :min="acao.dtaInicio"
                                        v-model="acao.dtaFim"
                                        @change="acao.indAcaoAlterada = true"
                                        :max="objetoPassoGeralContrato.dtaVigenciaFim"
                                        @input="setaDataFimFormatada(acao)" />
                                    </v-menu>
                                  </td>
                                </tr>
                              </v-form>
                            </td>
                            <td v-if="isTipoContratoSamsung || isTipoContratoNivelFamilia || isTipoContratoEpoca">
                              <span v-if="isMetaNaoAplica(acao)">
                                {{ $t('label.nao_aplica') }}
                              </span>
                              <div v-else-if="acaoCalculosApuracao(acao).length > 1">
                                <v-form :id="`form-calculo-apuracao-${categoria.id}-${acao.idAcaoTipo}`"
                                  :ref="`form-calculo-apuracao-${categoria.id}-${acao.idAcaoTipo}`" lazy-validation autocomplete="off">
                                  <v-checkbox
                                    :id="`acao_categoria_calculo_apuracao-${categoria.id}-${acao.idAcaoTipo}-${b}`"
                                    :key="`acao_categoria_calculo_apuracao-${categoria.id}-${acao.idAcaoTipo}-${b}`"
                                    v-for="(b, index) in acaoCalculosApuracao(acao)"
                                    v-model="acao.calculoApuracao"
                                    dense
                                    :hide-details="index !== acaoCalculosApuracao(acao).length - 1"
                                    class="mt-0"
                                    :disabled="somenteLeitura"
                                    @click.native="acao.indAcaoAlterada = true, valorCalculoApuracaoAlterado(acao), revalidaValor(categoria, acao)"
                                    :rules="rules.requiredFunction(acao, 'calculoApuracao')"
                                    :label="`${$t(`configuracao.CALCULO_APURACAO.${b}`)}`"
                                    :value="b">
                                  </v-checkbox>
                                </v-form>
                              </div>
                              <span v-else-if="acaoCalculosApuracao(acao).length == 1">
                                {{ $t(`configuracao.CALCULO_APURACAO.${acaoCalculosApuracao(acao)[0]}`) }}
                              </span>
                            </td>
                            <td v-if="acao.configuracao && acaoBonificacoes(acao)">
                              <div v-if="acaoBonificacoes(acao).length > 1">
                                <v-form :id="`form-tipo-verba-${categoria.id}-${acao.idAcaoTipo}`"
                                  :ref="`form-tipo-verba-${categoria.id}-${acao.idAcaoTipo}`" lazy-validation autocomplete="off">
                                  <v-checkbox
                                    :id="`acao_categoria_tipo_verba-${categoria.id}-${acao.idAcaoTipo}-${b}`"
                                    :key="`acao_categoria_tipo_verba-${categoria.id}-${acao.idAcaoTipo}-${b}`"
                                    v-for="(b, index) in acaoBonificacoes(acao)"
                                    v-model="acao.formaBonificacao"
                                    dense
                                    :hide-details="index !== acaoBonificacoes(acao).length - 1"
                                    class="mt-0"
                                    :disabled="somenteLeitura || indSemVerba"
                                    @change="revalidaValor(categoria, acao), acao.indAcaoAlterada = true"
                                    :rules="rules.requiredFunction(acao, 'formaBonificacao')"
                                    :label="`${$t(`configuracao.BONIFICACAO.${b}`)}`"
                                    :value="b">
                                  </v-checkbox>
                                </v-form>
                              </div>
                              <span v-else-if="acaoBonificacoes(acao).length == 1">
                                {{ $t(`configuracao.BONIFICACAO.${acaoBonificacoes(acao)[0]}`) }}
                              </span>
                            </td>
                            <td>
                              <div v-if="!isMetaIntervalo(acao) && !isMetaCumulativa(acao)">
                                <v-form :id="`form-acao-categoria-valor-${categoria.id}-${acao.idAcaoTipo}`"
                                  :ref="`form-acao-categoria-valor-${categoria.id}-${acao.idAcaoTipo}`"
                                  lazy-validation autocomplete="off">
                                  <input-money
                                    v-if="!acao.formaBonificacao || isVerbaFixo(acao)"
                                    :id="`acao_categoria_valor_monetario-${categoria.id}-${acao.idAcaoTipo}`"
                                    v-model="acao.valorBonificacao"
                                    :disabled="somenteLeitura || indSemVerba"
                                    :rules="[v => rules.valorMinimo(acao, v, 0.01)]"
                                    @change.native="revalidaForms(categoria, acao)"
                                    @change="acao.indAcaoAlterada = true"
                                    class="v-text-field"/>
                                  <v-text-field
                                    v-else-if="isVerbaPercentual(acao)"
                                    :id="`acao_categoria_valor_percentual-${categoria.id}-${acao.idAcaoTipo}`"
                                    v-model="acao.valorBonificacao"
                                    :disabled="somenteLeitura || indSemVerba"
                                    :rules="[v => rules.valorMinimo(acao, v, 0.01), v => rules.valorMaximo(v, 100)]"
                                    @change.native="revalidaForms(categoria, acao)"
                                    @change="acao.indAcaoAlterada = true"
                                    type="Number"
                                    min="0"
                                    suffix="%"/>
                                  <v-text-field
                                    v-else
                                    :id="`acao_categoria_valor_variavel-${categoria.id}-${acao.idAcaoTipo}`"
                                    v-model="acao.valorBonificacao"
                                    :disabled="somenteLeitura || indSemVerba"
                                    :rules="[v => rules.valorMinimo(acao, v, 0.01)]"
                                    @change.native="revalidaForms(categoria, acao)"
                                    @change="acao.indAcaoAlterada = true"
                                    type="Number"
                                    min="0"/>
                                </v-form>
                              </div>
                              <div v-else>
                                <v-btn
                                  icon
                                  @click="isMetaCumulativa(acao) ? exibirModalMetaIntervaloCumulativa(categoria, acao)
                                    : exibirModalMetaIntervalo(categoria, acao)"
                                  class="mx-2"
                                  :disabled="indSemVerba"
                                >
                                  <v-icon>
                                    add_box
                                  </v-icon>
                                </v-btn>
                                <v-tooltip top v-if="acao.calculoApuracaoAlterado">
                                  <template v-slot:activator="{ on }">
                                    <v-icon dark color="warning" v-on="on">warning</v-icon>
                                  </template>
                                  <span>{{ $tc('message.calculo_apuracao_alterado_revisar_metas', 1) }}</span>
                                </v-tooltip>
                              </div>
                            </td>
                            <td v-if="acao.configuracao && acaoPeriodos(acao)">
                              <div v-if="acaoPeriodos(acao).length > 1">
                                <v-form :id="`form-periodicidade-${categoria.id}-${acao.idAcaoTipo}`"
                                  :ref="`form-periodicidade-${categoria.id}-${acao.idAcaoTipo}`" lazy-validation autocomplete="off">
                                  <v-checkbox
                                    :id="`acao_categoria_periodicidade-${categoria.id}-${acao.idAcaoTipo}-${p}`"
                                    :key="`acao_categoria_periodicidade-${categoria.id}-${acao.idAcaoTipo}-${p}`"
                                    v-for="(p, index) in acaoPeriodos(acao)"
                                    v-model="acao.periodosApuracao"
                                    dense
                                    :hide-details="index !== acaoPeriodos(acao).length - 1"
                                    class="mt-0"
                                    :disabled="somenteLeitura || indSemVerba"
                                    @change="acao.indAcaoAlterada = true, revalidaValor(categoria, acao)"
                                    :rules="rules.requiredFunction(acao, 'periodosApuracao')"
                                    :label="`${$tc('label.' + p.toLowerCase(), 1)}`"
                                    :value="p"
                                  >
                                  </v-checkbox>
                                </v-form>
                              </div>
                              <span v-else-if="acaoPeriodos(acao).length == 1">
                                {{ $t(`configuracao.PERIODICIDADE.${acaoPeriodos(acao)[0]}`) }}
                              </span>
                            </td>
                            <td v-if="isTipoContratoSamsung || isTipoContratoNivelFamilia || isTipoContratoEpoca">
                              <span v-if="!acaoCalculosBonificacao(acao).length">
                                {{ $t('label.nao_aplica') }}
                              </span>
                              <div v-if="acaoCalculosBonificacao(acao).length > 1">
                                <v-form :id="`form-calculo-bonificacao-${categoria.id}-${acao.idAcaoTipo}`"
                                  :ref="`form-calculo-bonificacao-${categoria.id}-${acao.idAcaoTipo}`" lazy-validation autocomplete="off">
                                  <v-checkbox
                                    :id="`bonificacao-${categoria.id}-${acao.idAcaoTipo}-${b}`"
                                    :key="`bonificacao-${categoria.id}-${acao.idAcaoTipo}-${b}`"
                                    v-for="(b, index) in acaoCalculosBonificacao(acao)"
                                    v-model="acao.calculoBonificacao"
                                    dense
                                    :hide-details="index !== acaoCalculosBonificacao(acao).length - 1"
                                    class="mt-0"
                                    :disabled="somenteLeitura || indSemVerba"
                                    @change="acao.indAcaoAlterada = true, revalidaValor(categoria, acao)"
                                    :rules="rules.requiredFunction(acao, 'calculoBonificacao')"
                                    :label="`${$t(`configuracao.CALCULO_BONIFICACAO.${b}`)}`"
                                    :value="b">
                                  </v-checkbox>
                                </v-form>
                              </div>
                              <span v-else-if="acaoCalculosBonificacao(acao).length == 1">
                                {{ $t(`configuracao.CALCULO_BONIFICACAO.${acaoCalculosBonificacao(acao)[0]}`) }}
                              </span>
                            </td>
                            <td v-if="isTipoContratoSamsung" class="text-center">
                              <v-btn
                                icon
                                class="mx-2"
                                @click.stop="abrirModalSelecaoProduto(acao, categoria)"
                                v-if="ativarAdicionarProdutos(acao)"
                              >
                                <v-tooltip top v-if="acao.idsProdutos && acao.idsProdutos.length">
                                  <template v-slot:activator="{ on }">
                                    <v-icon v-on="on">category</v-icon>
                                  </template>
                                  <span
                                  v-text="$t('message.quantidade_produtos_selecionados', {text: acao.idsProdutos.length})"
                                  />
                                </v-tooltip>
                                <v-tooltip top v-else>
                                  <template v-slot:activator="{ on }">
                                    <v-icon v-on="on">
                                    add_box
                                    </v-icon>
                                  </template>
                                  <span v-text="$t('message.todos_produtos')" />
                                </v-tooltip>
                              </v-btn>
                              <span v-else>
                                {{ $t('label.nao_aplica') }}
                              </span>
                            </td>
                            <td v-if="isTipoContratoNivelFamilia || isTipoContratoEpoca" class="text-center">
                              <v-autocomplete
                                :id="`verba_variavel_familia-${categoria.id}`"
                                :ref="`verba_variavel_familia-${categoria.id}`"
                                name="verba_variavel_familia"
                                v-model="acao.idsFamilias"
                                :filter="filtroFamilia"
                                :items="listaFamilias[`verba_variavel_familia-${categoria.id}`]"
                                @click.native="isTipoContratoEpoca ? buscaFamiliasEpoca(acao.idCategoria) : buscaFamilias(acao.idCategoria)"
                                :no-data-text="$tc('message.nenhum_registro', 1)"
                                item-text="codNomeFamilia"
                                item-value="id"
                                :disabled="somenteLeitura"
                                :label="`${$tc('label.familia', 1)}`"
                                :placeholder="$tc('label.digite_para_buscar', 1)"
                                @change="acao.indAcaoAlterada = true"
                                clearable
                                chips
                                multiple
                                deletable-chips>
                              </v-autocomplete>
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-data-table>
                  </v-col>
                </v-row>
              </v-container>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-row>
    </v-container>

    <v-dialog
      id="dialog_acao_categoria_valor_intervalo"
      v-model="exibeModalMetaIntervalo"
      transition="slide-x-transition"
      overlay-opacity="0"
      persistent
      width="40%">
      <planejamento-contrato-form-meta-intervalo
        :somenteLeitura="somenteLeitura"
        :calculo-volume="isCalculoVolume() ? acaoSelecionada.calculoApuracaoVolume : calculoVolume"
        :calculo-valor="isCalculoValor() ? acaoSelecionada.calculoApuracaoValor : calculoValor"
        :calculo-percentual="isCalculoPercentual() ? acaoSelecionada.calculoApuracaoPercentual : calculoPercentual"
        :unidade-quantidade="metaPorQuantidade"
        :subtitulo="subtituloModalIntervalo"
        :acao="acaoSelecionada"
        :verbaPercentual="isVerbaPercentual(acaoSelecionada)"
        :sigla="calculoPercentual ? '%' : ''"
        :metas="acaoSelecionada.metas"
        @PlanejamentoContratoFormMetaIntervalo__fecharModalMetaIntervalo="fecharModalMetaIntervalo"
      />
    </v-dialog>

    <v-dialog
      id="dialog_acao_categoria_valor_intervalo_cumulativa"
      v-model="exibeModalMetaIntervaloCumulativa"
      transition="slide-x-transition"
      overlay-opacity="0"
      persistent
      width="60%">
      <planejamento-contrato-form-meta-intervalo-cumulativa
        v-if="exibeModalMetaIntervaloCumulativa"
        :somenteLeitura="somenteLeitura"
        :unidade-quantidade="metaPorQuantidade"
        :acao="acaoSelecionada"
        :categoria="categoriaSelecionada"
        :subtitulo="subtituloModalIntervalo"
        :verbaPercentual="isVerbaPercentual(acaoSelecionada)"
        :sigla="calculoPercentual ? '%' : ''"
        :metas="acaoSelecionada.metas"
        :configuracao="configuracao"
        :get-objeto-outro-passo="getObjetoOutroPasso"
        @PlanejamentoContratoFormMetaIntervalo__fecharModalMetaIntervalo="fecharModalMetaIntervaloCumulativa"
      />
    </v-dialog>

    <confirm
      ref="removerCategoriaDialog"
      :message="mensagemCancelar"
      :persistent="true"
      @agree="funcCancelar">
    </confirm>
    <confirm
      ref="modalRemoverAcao"
      :message="mensagemConfirmacao"
      @agree="removerCopia">
    </confirm>
    <planejamento-contrato-modal-selecao-produto
      ref="modalSelecaoProduto"
      :acao="acaoSelecionada"
      :somente-leitura="somenteLeitura"
      :configuracao="configuracao"
      :categoria-selecionada="categoriaSelecionada"
      :familia-selecionada="familiaSelecionada"
      :origem="dadosOrigem"
      @ModalSelecaoProduto__atualizarChave="atualizarChave"
      @GetObjetoOutroPasso="getObjetoOutroPasso"
      />
  </div>
</template>
<script>
import { formatDateDDMMYYYY } from '../../../../common/functions/date-utils';
import { copyObject } from '../../../../common/functions/helpers';
import { generateComputed } from '../../../../common/functions/roles-computed-generator';
import InputMoney from '../../../../shared-components/inputs/InputMoney';
import Confirm from '../../../../shared-components/vuetify/dialog/Confirm';
import PlanejamentoContratoFormAcoesControleItens from './PlanejamentoContratoFormAcoesControleItens';
import PlanejamentoContratoDialogCancelarCategoria from './PlanejamentoContratoDialogCancelarCategoria';
import PlanejamentoContratoFormMetaIntervalo from './PlanejamentoContratoFormMetaIntervalo';
import PlanejamentoContratoFormMetaIntervaloCumulativa from './PlanejamentoContratoFormMetaIntervaloCumulativa';
import AcaoConfiguracao from '../../../planejamento-acao/AcaoConfiguracao';
import PlanejamentoContratoFormAcoesControlePopoverCopiaCategoria from './PlanejamentoContratoFormAcoesControlePopoverCopiaCategoria';
import PlanejamentoContratoModalSelecaoProduto from './PlanejamentoContratoModalSelecaoProduto';
import TooltipAjuda from '../../../../shared-components/TooltipAjuda';

export default {
  components: {
    InputMoney,
    Confirm,
    PlanejamentoContratoDialogCancelarCategoria,
    PlanejamentoContratoFormMetaIntervalo,
    PlanejamentoContratoFormAcoesControlePopoverCopiaCategoria,
    PlanejamentoContratoModalSelecaoProduto,
    TooltipAjuda,
    PlanejamentoContratoFormMetaIntervaloCumulativa,
  },
  mixins: [PlanejamentoContratoFormAcoesControleItens],
  props: {
    contratoEdicao: Object,
    configuracao: Object,
    novoContrato: Boolean,
    somenteLeitura: Boolean,
    objetoPassoGeralContrato: Object,
    categoriasAdicionadas: {
      type: Array,
    },
    calculoVolume: {
      type: Boolean,
      default: false,
    },
    calculoValor: {
      type: Boolean,
      default: true,
    },
    calculoPercentual: {
      type: Boolean,
      default: false,
    },
    indSemVerba: {
      type: Boolean,
      default: false,
    },
    listaCategorias: {
      type: Array,
      default: [],
    },
    getObjetoOutroPasso: {
      type: Function,
      required: true,
    },
  },
  watch: {
    categoriasAdicionadas(value) {
      if (value && value.length
        && (!this.listaCategoriasCopia || !this.listaCategoriasCopia.length
        || !this.listaCategoriasCopia.length !== value.length)) {
        this.setCategorias();
      }
    },
  },
  computed: {
    ...generateComputed('CONTRATO', [
      'canEdit',
    ]),
    subtituloModalIntervalo() {
      if (this.categoriaSelecionada && this.acaoSelecionada) {
        return ' - '.concat(this.categoriaSelecionada.nomeCategoria)
          .concat(' - ').concat(this.acaoSelecionada.nomeAcaoTipo);
      }
      return '';
    },
    metaPorQuantidade() {
      return !!this.acaoSelecionada && !!this.acaoSelecionada.configuracao
        && !!this.acaoSelecionada.configuracao.templateCalculo
        && this.acaoSelecionada.configuracao.templateCalculo === 'META_POR_QUANTIDADE';
    },
    isTipoContratoSamsung() {
      return this.configuracao.isTipoContratoSamsung;
    },
    isTipoContratoEpoca() {
      return this.configuracao.isTipoContratoEpoca;
    },
    isTipoContratoNivelFamilia() {
      return this.configuracao.isTipoContratoNivelFamilia;
    },
    cabecalho() {
      if (this.isTipoContratoSamsung) {
        return this.cabecalhoContratoSamsung;
      }
      if (this.isTipoContratoEpoca) {
        return this.cabecalhoContratoEpoca;
      }
      if (this.isTipoContratoNivelFamilia) {
        return this.cabecalhoContratoNivelFamilia;
      }
      return this.cabecalhoContratoPadrao;
    },
    idGrupoFornecedor() {
      return this.objetoPassoGeralContrato.grupoFornecedor
        ? this.objetoPassoGeralContrato.grupoFornecedor.id : this.objetoPassoGeralContrato.fornecedor.idGrupoFornecedor;
    },
  },
  data() {
    return {
      planejamentoAcaoResource: this.$api.planejamentoAcaoCadastro(this.$resource),
      configuracaoResource: this.$api.planejamentoAcaoConfiguracao(this.$resource),
      listaCategoriasCopia: [],

      painel: [],
      panelKey: 0,
      iniciandoPainel: true,
      itemMetaIntervalo: 'add_box',
      iconeMetaIntervalo: ['add_box'],
      acaoSelecionada: {
        metas: [],
      },
      acaoRemoverSelecionada: {
        metas: [],
      },
      categoriasAcoesRemover: null,
      categoriaSelecionada: {},
      exibeModalMetaIntervalo: false,
      mensagemConfirmacao: null,

      formulario: {
        grupoFornecedor: null,
        fornecedor: null,
        prazoVencimentoAcao: null,
        tipoPagamento: null,
        indPossuiPassoFornecedor: true,
      },
      produtosSelecionados: [],
      linhasProduto: [],
      familiaSelecionada: null,
      objetoPassoGeral: null,
      dadosOrigem: null,
      chave: 0,

      rules: {
        required: (value) => !!value || this.$t('message.campo_obrigatorio'),
        valorMinimo: (acao, value, min) => {
          const isPeriodoArray = Array.isArray(acao.periodosApuracao);
          const deveValidarValor = !!acao.formaBonificacao && !!acao.periodosApuracao
            && (!isPeriodoArray || (isPeriodoArray && acao.periodosApuracao.length > 0));
          const valorValido = !deveValidarValor || value >= min;
          return valorValido || this.$t('errors.valor.maior_zero_inteiro');
        },
        valorMaximo: (value, max) => value <= max || this.$t('errors.valor.maximo', { max }),
        requiredFunction: (obj, valueLabel) => [
          () => {
            const val = obj[valueLabel];
            const msg = this.$t('message.campo_obrigatorio');
            return ((!!(val) && ((obj.valorBonificacao && parseInt(obj.valorBonificacao, 10) > 0) || (obj.metas && obj.metas.length > 0)))
            || ((!obj.valorBonificacao || parseInt(obj.valorBonificacao, 10) === 0 || parseInt(obj.valorBonificacao, 10) < 0)
            && (!obj.metas || !obj.metas.length > 0))) || msg;
          },
        ],
        dateValidation: (obj, valueLabel) => [
          () => {
            const val = obj[valueLabel];
            let msg = this.$t('message.campo_obrigatorio');
            let dataValida = true;
            if (!!val && (val.length !== 10 || !this.moment(val, 'DD-MM-YYYY').isValid())) {
              dataValida = false;
              msg = this.$t('message.data_invalida');
            }
            if (valueLabel === 'dtaInicioFormatada') {
              return ((!!val && dataValida && ((obj.valorBonificacao && parseInt(obj.valorBonificacao, 10) > 0)
                || (obj.metas && obj.metas.length > 0)))
                || ((!obj.valorBonificacao || parseInt(obj.valorBonificacao, 10) === 0 || parseInt(obj.valorBonificacao, 10) < 0)
                && (!obj.metas || !obj.metas.length > 0))) || msg;
            }
            let dataFimIgualOuMaiorInicio = true;
            if (!this.isDataFimIgualOuMaiorInicio(obj)) {
              dataFimIgualOuMaiorInicio = false;
              msg = this.$t('message.data_inicial_maior_final');
            }
            return ((!!val && dataValida && dataFimIgualOuMaiorInicio
              && ((obj.valorBonificacao && parseInt(obj.valorBonificacao, 10) > 0) || (obj.metas && obj.metas.length > 0)))
              || ((!obj.valorBonificacao || parseInt(obj.valorBonificacao, 10) === 0 || parseInt(obj.valorBonificacao, 10) < 0)
              && (!obj.metas || !obj.metas.length > 0))) || msg;
          },
        ],
      },
      cabecalhoContratoPadrao: [
        {
          text: this.$tc('label.acao', 1), value: 'acao', sortable: false, width: '35%',
        },
        {
          text: this.$tc('label.verba', 1), value: 'verba', sortable: false, width: '20%',
        },
        {
          text: this.$tc('label.valor', 1), value: 'valor', sortable: false, width: '20%',
        },
        {
          text: this.$tc('label.periodicidade', 1), value: 'periodicidade', sortable: false, width: '20%',
        },
      ],
      cabecalhoContratoSamsung: [
        {
          text: this.$tc('', 1), value: 'botoes', sortable: false, width: '5%',
        },
        {
          text: this.$tc('label.acao', 1), value: 'acao', sortable: false, width: '25%',
        },
        {
          text: this.$tc('label.periodo_negociacao', 1), value: 'periodo_negociacao', sortable: false, width: '15%',
        },
        {
          text: this.$tc('label.calculo_apuracao', 1), value: 'calculo_apuracao', sortable: false, width: '15%',
        },
        {
          text: this.$tc('label.verba', 1), value: 'verba', sortable: false, width: '5%',
        },
        {
          text: this.$tc('label.valor', 1), value: 'valor', sortable: false, width: '15%',
        },
        {
          text: this.$tc('label.periodicidade', 1), value: 'periodicidade', sortable: false, width: '10%',
        },
        {
          text: this.$tc('label.calculo_bonificacao', 1), value: 'calculo_bonificacao', sortable: false, width: '20%',
        },
        {
          text: this.$tc('label.produto', 1), value: 'produto', sortable: false, width: '5%',
        },
      ],
      cabecalhoContratoEpoca: [
        {
          text: this.$tc('', 1), value: 'botoes', sortable: false, width: '5%',
        },
        {
          text: this.$tc('label.acao', 1), value: 'acao', sortable: false, width: '15%',
        },
        {
          text: this.$tc('label.periodo_negociacao', 1), value: 'periodo_negociacao', sortable: false, width: '10%',
        },
        {
          text: this.$tc('label.calculo_apuracao', 1), value: 'calculo_apuracao', sortable: false, width: '10%',
        },
        {
          text: this.$tc('label.verba', 1), value: 'verba', sortable: false, width: '5%',
        },
        {
          text: this.$tc('label.valor', 1), value: 'valor', sortable: false, width: '5%',
        },
        {
          text: this.$tc('label.periodicidade', 1), value: 'periodicidade', sortable: false, width: '10%',
        },
        {
          text: this.$tc('label.calculo_bonificacao', 1), value: 'calculo_bonificacao', sortable: false, width: '10%',
        },
        {
          text: this.$tc('label.marca', 1), value: 'marca', sortable: false, width: '15%',
        },
      ],
      cabecalhoContratoNivelFamilia: [
        {
          text: this.$tc('', 1), value: 'botoes', sortable: false, width: '3%',
        },
        {
          text: this.$tc('label.acao', 1), value: 'acao', sortable: false, width: '18%',
        },
        {
          text: this.$tc('label.periodo_negociacao', 1), value: 'periodo_negociacao', sortable: false, width: '15%',
        },
        {
          text: this.$tc('label.calculo_apuracao', 1), value: 'calculo_apuracao', sortable: false, width: '15%',
        },
        {
          text: this.$tc('label.verba', 1), value: 'verba', sortable: false, width: '5%',
        },
        {
          text: this.$tc('label.valor', 1), value: 'valor', sortable: false, width: '7%',
        },
        {
          text: this.$tc('label.periodicidade', 1), value: 'periodicidade', sortable: false, width: '10%',
        },
        {
          text: this.$tc('label.calculo_bonificacao', 1), value: 'calculo_bonificacao', sortable: false, width: '10%',
        },
        {
          text: this.$tc('label.familia', 1), value: 'familia', sortable: false, width: '17%',
        },
      ],
      isCopia: false,
      exibeModalMetaIntervaloCumulativa: false,
      listaFamilias: [],
    };
  },
  methods: {
    abrirModalCategoriaFornecedor(categoria) {
      this.$emit('PlanejamentoContratoModalCategoriaFornecedor__AbrirModalCategoriaFornecedor', categoria, true);
    },
    isMetaIntervalo(acao) {
      return !!acao.configuracao && acao.configuracao.configuracao.passo5.apuracao.meta === 'INTERVALO';
    },
    isVerbaFixo(acao) {
      return !!acao.formaBonificacao && acao.formaBonificacao === 'FIXO';
    },
    isVerbaPercentual(acao) {
      return !!acao.formaBonificacao && acao.formaBonificacao === 'PERCENTUAL';
    },
    isDataFimMaiorInicio(acao) {
      const dtInicio = this.moment(acao.dtaInicio, 'YYYY-MM-DD');
      const dtFinal = this.moment(acao.dtaFim, 'YYYY-MM-DD');
      return dtFinal.isAfter(dtInicio);
    },
    isDataFimIgualOuMaiorInicio(acao) {
      const dtInicio = this.moment(acao.dtaInicio, 'YYYY-MM-DD');
      const dtFinal = this.moment(acao.dtaFim, 'YYYY-MM-DD');
      return dtFinal.isSameOrAfter(dtInicio);
    },
    isCalculoVolume() {
      if (this.isTipoContratoSamsung || this.isTipoContratoNivelFamilia || this.isTipoContratoEpoca) {
        return true;
      }
      return false;
    },
    isCalculoValor() {
      if (this.isTipoContratoSamsung || this.isTipoContratoNivelFamilia || this.isTipoContratoEpoca) {
        return true;
      }
      return false;
    },
    isCalculoPercentual() {
      if (this.isTipoContratoSamsung || this.isTipoContratoNivelFamilia || this.isTipoContratoEpoca) {
        return true;
      }
      return false;
    },
    setaDataInicioFormatada(acao) {
      if (acao && acao.dtaInicio) {
        acao.dtaInicioFormatada = formatDateDDMMYYYY(acao.dtaInicio);
      }
      acao.pickerDataInicio = false;
    },
    setaDataFimFormatada(acao) {
      if (acao && acao.dtaFim) {
        acao.dtaFimFormatada = formatDateDDMMYYYY(acao.dtaFim);
      }
      acao.pickerDataFim = false;
    },
    exibirModalMetaIntervalo(categoria, acao) {
      acao.calculoApuracaoAlterado = false;
      this.categoriaSelecionada = categoria;
      this.acaoSelecionada = acao;
      this.exibeModalMetaIntervalo = true;
    },
    fecharModalMetaIntervalo() {
      this.exibeModalMetaIntervalo = false;
      this.revalidaForms(this.categoriaSelecionada, this.acaoSelecionada);
    },
    setCategorias() {
      this.categoriasAdicionadas.forEach((cat) => {
        if (!!cat.acoes && cat.acoes.length) {
          cat.acoes.forEach((acao) => {
            acao.idsCategoriasAgrupadas = cat.idsCategoriasAgrupadas;
            if (!acao.configuracao) {
              this.carregarConfiguracao(acao, cat).then(() => {
                if (acao.isProdutoObrigatorio
                  && acao.idsProdutos && acao.idsProdutos.length) {
                  acao.idsProdutosObrigatorios = [...acao.idsProdutos];
                } else if (acao.isProdutoExcecao
                  && acao.idsProdutos && acao.idsProdutos.length) {
                  acao.idsProdutosExcecao = [...acao.idsProdutos];
                }
              });
            }
            if (this.configuracao.tiposAcao.find((tipoAcao) => tipoAcao.idAcaoTipo === acao.idAcaoTipo)) {
              const tiposAcao = this.configuracao.tiposAcao.find((tipoAcao) => tipoAcao.idAcaoTipo === acao.idAcaoTipo);
              acao.indAtivarDuplicidade = tiposAcao.indAtivarDuplicidade;
            }
          });
        }
      });

      this.listaCategoriasCopia = this.categoriasAdicionadas.map((el, index) => {
        const obj = {
          index,
          acoes: el.acoes.filter((acao) => !!acao.id),
          codNome: el.codNome,
          configuracao: el.configuracao,
          id: el.id,
          idExterno: el.idExterno,
          idsCategoriasAgrupadas: el.idsCategoriasAgrupadas,
          idsCategoriasRemovidas: el.idsCategoriasRemovidas,
          fornecedores: el.fornecedores || [],
          nomeCategoria: el.nomeCategoria,
          nomeCategoriaAgrupada: el.idExterno.concat(' :: ').concat(el.nomeCategoria),
        };
        if (this.novoContrato || this.contratoEdicao.passoGeral.status === 'EM_CADASTRO'
          || this.contratoEdicao.passoGeral.status === 'EM_ANALISE') {
          obj.acoes = el.acoes || [];
        }

        if (el.idsCategoriasAgrupadas.length > 1) {
          el.idsCategoriasAgrupadas.forEach((id) => {
            if (id !== el.idsCategoriasAgrupadas[0]) {
              const categoriaAgrupada = this.listaCategorias.find((cat) => cat.id === id);
              obj.nomeCategoriaAgrupada = obj.nomeCategoriaAgrupada
                .concat(', ')
                .concat(categoriaAgrupada.idExterno)
                .concat(' :: ')
                .concat(categoriaAgrupada.nomeCategoria);
            }
          });
        }
        return obj;
      });
      this.panelKey += 1;
    },
    valida() {
      let validado = true;
      for (let i = 0; i < this.categoriasAdicionadas.length; i += 1) {
        if (!this.categoriasAdicionadas[i].fornecedores || !this.categoriasAdicionadas[i].fornecedores.length) {
          validado = false;
          this.$toast(this.$t('errors.contrato.categoria_sem_fornecedor'));
          return validado;
        }

        for (let j = 0; j < this.categoriasAdicionadas[i].acoes.length; j += 1) {
          if ((this.categoriasAdicionadas[i].acoes[j].valorBonificacao
            && parseInt(this.categoriasAdicionadas[i].acoes[j].valorBonificacao, 10) > 0)
            || (this.categoriasAdicionadas[i].acoes[j].metas
            && this.categoriasAdicionadas[i].acoes[j].metas.length)) {
            let formEspecificoValido = true;

            // valida radio tipo de verba
            let formName = 'form-tipo-verba-'.concat(this.categoriasAdicionadas[i].id)
              .concat('-').concat(this.categoriasAdicionadas[i].acoes[j].idAcaoTipo);
            let formEspecifico = this.$refs[formName];
            if (formEspecifico !== undefined) {
              for (let k = 0; k < formEspecifico.length; k += 1) {
                if (formEspecifico[k] !== undefined) {
                  formEspecificoValido = formEspecifico[k].validate();
                  if (formEspecificoValido) {
                    formEspecifico[k].resetValidation();
                  } else {
                    validado = false;
                    return validado;
                  }
                }
              }
            }

            // valida radio periodicidade
            formName = 'form-periodicidade-'.concat(this.categoriasAdicionadas[i].id)
              .concat('-').concat(this.categoriasAdicionadas[i].acoes[j].idAcaoTipo);
            formEspecifico = this.$refs[formName];
            if (formEspecifico !== undefined) {
              for (let k = 0; k < formEspecifico.length; k += 1) {
                if (formEspecifico[k] !== undefined) {
                  formEspecificoValido = formEspecifico[k].validate();
                  if (formEspecificoValido) {
                    formEspecifico[k].resetValidation();
                  } else {
                    validado = false;
                    return validado;
                  }
                }
              }
            }

            // valida valor
            if (!this.isMetaIntervalo(this.categoriasAdicionadas[i].acoes[j])) {
              formName = 'form-acao-categoria-valor-'.concat(this.categoriasAdicionadas[i].id)
                .concat('-').concat(this.categoriasAdicionadas[i].acoes[j].idAcaoTipo);
              formEspecifico = this.$refs[formName];
              if (formEspecifico !== undefined) {
                for (let k = 0; k < formEspecifico.length; k += 1) {
                  if (formEspecifico[k] !== undefined) {
                    formEspecificoValido = formEspecifico[k].validate();
                    if (formEspecificoValido) {
                      formEspecifico[k].resetValidation();
                    } else {
                      validado = false;
                      return validado;
                    }
                  }
                }
              }
            }
            if (this.isTipoContratoSamsung || this.isTipoContratoNivelFamilia || this.isTipoContratoEpoca) {
              // valida datas
              formName = 'form-periodo-negociacao-'.concat(this.categoriasAdicionadas[i].id)
                .concat('-').concat(this.categoriasAdicionadas[i].acoes[j].idAcaoTipo);
              formEspecifico = this.$refs[formName];
              if (formEspecifico !== undefined) {
                for (let k = 0; k < formEspecifico.length; k += 1) {
                  if (formEspecifico[k] !== undefined) {
                    formEspecificoValido = formEspecifico[k].validate();
                    if (formEspecificoValido) {
                      formEspecifico[k].resetValidation();
                    } else {
                      validado = false;
                      return validado;
                    }
                  }
                }
              }

              // valida calculo apuracao
              formName = 'form-calculo-apuracao-'.concat(this.categoriasAdicionadas[i].id)
                .concat('-').concat(this.categoriasAdicionadas[i].acoes[j].idAcaoTipo);
              formEspecifico = this.$refs[formName];
              if (formEspecifico !== undefined) {
                for (let k = 0; k < formEspecifico.length; k += 1) {
                  if (formEspecifico[k] !== undefined) {
                    formEspecificoValido = formEspecifico[k].validate();
                    if (formEspecificoValido) {
                      formEspecifico[k].resetValidation();
                    } else {
                      validado = false;
                      return validado;
                    }
                  }
                }
              }

              // valida calculo bonificacao
              formName = 'form-calculo-bonificacao-'.concat(this.categoriasAdicionadas[i].id)
                .concat('-').concat(this.categoriasAdicionadas[i].acoes[j].idAcaoTipo);
              formEspecifico = this.$refs[formName];
              if (formEspecifico !== undefined) {
                for (let k = 0; k < formEspecifico.length; k += 1) {
                  if (formEspecifico[k] !== undefined) {
                    formEspecificoValido = formEspecifico[k].validate();
                    if (formEspecificoValido) {
                      formEspecifico[k].resetValidation();
                    } else {
                      validado = false;
                      return validado;
                    }
                  }
                }
              }
            }
          }
        }
      }
      return validado;
    },
    validaAcoesDuplicadasCategoriasNaoAgrupadas() {
      const objResposta = {
        validado: true,
        categoriasDuplicadas: [],
      };
      /**
       * Inicia loop das categorias
       */
      for (let i = 0; i < this.listaCategoriasCopia.length; i += 1) {
        for (let j = 0; j < this.listaCategoriasCopia.length; j += 1) {
          if (j > i
            && this.isArraysIguais(this.listaCategoriasCopia[i].fornecedores, this.listaCategoriasCopia[j].fornecedores)) {
            const categoriaA = this.listaCategoriasCopia[i];
            const categoriaB = this.listaCategoriasCopia[j];
            let qtdAcoesIguais = 0;

            /**
             * Inicia loop das acoes
             */
            for (let a = 0; a < categoriaA.acoes.length; a += 1) {
              for (let b = 0; b < categoriaB.acoes.length; b += 1) {
                const acaoA = categoriaA.acoes[a];
                const acaoB = categoriaB.acoes[b];
                if (acaoA.idAcaoTipo === acaoB.idAcaoTipo) {
                  /**
                   * se não possuem valores, então ambas não foram preenchidas e são consideradas iguais
                   */
                  if ((!acaoA.valorBonificacao || parseInt(acaoA.valorBonificacao, 10) === 0) && (!acaoA.metas || !acaoA.metas.length > 0)
                    && (!acaoB.valorBonificacao || parseInt(acaoB.valorBonificacao, 10) === 0) && (!acaoB.metas || !acaoB.metas.length > 0)) {
                    qtdAcoesIguais += 1;
                  } else {
                    const formaBonificacaoA = acaoA.formaBonificacao;
                    const formaBonificacaoB = acaoB.formaBonificacao;
                    const periodosApuracaoA = acaoA.periodosApuracao;
                    const periodosApuracaoB = acaoB.periodosApuracao;

                    /**
                     * Caso forma de bonificacao e periodos de apuracao sejam iguais, verifica se o valor (ou metas) sao iguais
                     */
                    if (formaBonificacaoA === formaBonificacaoB && periodosApuracaoA === periodosApuracaoB) {
                      if (acaoA.valorBonificacao && parseInt(acaoA.valorBonificacao, 10) > 0
                        && acaoB.valorBonificacao && parseInt(acaoB.valorBonificacao, 10) > 0) {
                        if (parseInt(acaoA.valorBonificacao, 10) === parseInt(acaoB.valorBonificacao, 10)) {
                          qtdAcoesIguais += 1;
                        }
                      } else {
                        let qtdMetasIguais = 0;
                        /**
                         * Inicia loop das metas
                         */
                        for (let m = 0; m < acaoA.metas.length; m += 1) {
                          for (let n = 0; n < acaoB.metas.length; n += 1) {
                            const metaA = acaoA.metas[m];
                            const metaB = acaoB.metas[n];
                            if (parseInt(metaA.vlrMetaDe, 10) === parseInt(metaB.vlrMetaDe, 10)
                              && ((!metaA.vlrMetaAte && !metaA.vlrMetaAte)
                              || (metaA.vlrMetaDe && metaA.vlrMetaAte && parseInt(metaA.vlrMetaAte, 10) === parseInt(metaB.vlrMetaAte, 10)))
                              && parseInt(metaA.vlrBonificacao, 10) === parseInt(metaB.vlrBonificacao, 10)) {
                              qtdMetasIguais += 1;
                            }
                          }
                        }
                        /**
                         * Se a qtde de metas iguais for a mesma qtd de metas existentes, entao as acoes sao iguais
                         */
                        if (qtdMetasIguais === acaoA.metas.length) {
                          qtdAcoesIguais += 1;
                        }
                      }
                    }
                  }
                }
              }
            }
            /**
             * Se a qtde de acoes iguais for a mesma qtd de acoes existentes, entao as categorias sao iguais
             * e estao erroneamente separadas. Deveriam estar agrupadas
             */
            if (qtdAcoesIguais === categoriaA.acoes.length) {
              objResposta.validado = false;
              if (categoriaA.idsCategoriasAgrupadas.length >= categoriaB.idsCategoriasAgrupadas.length) {
                const linhaExistente = objResposta.categoriasDuplicadas.find((cd) => cd.removerLinha.id === categoriaB.id);
                if (linhaExistente) {
                  objResposta.categoriasDuplicadas = objResposta.categoriasDuplicadas.filter((cd) => cd.removerLinha.id !== categoriaB.id);
                }

                const linhaIncluirExisteComoRemover = objResposta.categoriasDuplicadas.find((cd) => cd.removerLinha.id === categoriaA.id);

                objResposta.categoriasDuplicadas.push({
                  removerLinha: categoriaB,
                  incluirLinha: linhaIncluirExisteComoRemover ? linhaIncluirExisteComoRemover.incluirLinha : categoriaA,
                });
              } else {
                const linhaExistente = objResposta.categoriasDuplicadas.find((cd) => cd.removerLinha.id === categoriaA.id);
                if (linhaExistente) {
                  objResposta.categoriasDuplicadas = objResposta.categoriasDuplicadas.filter((cd) => cd.removerLinha.id !== categoriaA.id);
                }

                const linhaIncluirExisteComoRemover = objResposta.categoriasDuplicadas.find((cd) => cd.removerLinha.id === categoriaB.id);

                objResposta.categoriasDuplicadas.push({
                  removerLinha: categoriaA,
                  incluirLinha: linhaIncluirExisteComoRemover ? linhaIncluirExisteComoRemover.incluirLinha : categoriaB,
                });
              }
            }
          }
        }
      }
      return objResposta;
    },
    validaAcoesDuplicadasMesmaCategoria() {
      const indexList = [];
      const objResposta = {
        validado: true,
        acoesDuplicadas: [],
      };
      /**
       * Inicia loop das categorias
       */
      for (let i = 0; i < this.listaCategoriasCopia.length; i += 1) {
        const categoriaA = this.listaCategoriasCopia[i];

        /**
         * Inicia loop das acoes
         */
        for (let a = 0; a < categoriaA.acoes.length; a += 1) {
          for (let b = 0; b < categoriaA.acoes.length; b += 1) {
            const acaoA = categoriaA.acoes[a];
            const acaoB = categoriaA.acoes[b];
            if (b > a && acaoA.idAcaoTipo === acaoB.idAcaoTipo
              && ((acaoA.valorBonificacao && parseInt(acaoA.valorBonificacao, 10) > 0) || (acaoA.metas && acaoA.metas.length > 0))
              && ((acaoB.valorBonificacao && parseInt(acaoB.valorBonificacao, 10) > 0) || (acaoB.metas && acaoB.metas.length > 0))) {
              const formaBonificacaoA = acaoA.formaBonificacao;
              const formaBonificacaoB = acaoB.formaBonificacao;
              const periodosApuracaoA = acaoA.periodosApuracao;
              const periodosApuracaoB = acaoB.periodosApuracao;
              const calculoApuracaoA = acaoA.calculoApuracao;
              const calculoApuracaoB = acaoB.calculoApuracao;
              const calculoBonificacaoA = acaoA.calculoBonificacao;
              const calculoBonificacaoB = acaoB.calculoBonificacao;
              const dtaInicioA = acaoA.dtaInicio;
              const dtaInicioB = acaoB.dtaInicio;
              const dtaFimA = acaoA.dtaFim;
              const dtaFimB = acaoB.dtaFim;
              /**
               * Caso forma de bonificacao e periodos de apuracao sejam iguais, verifica se o valor (ou metas) sao iguais
               */
              if (formaBonificacaoA === formaBonificacaoB && periodosApuracaoA === periodosApuracaoB
                && calculoApuracaoA === calculoApuracaoB && calculoBonificacaoA === calculoBonificacaoB
                && ((dtaInicioA >= dtaInicioB && dtaInicioA <= dtaFimB)
                || (dtaFimA >= dtaInicioB && dtaFimA <= dtaFimB)
                || (dtaInicioB >= dtaInicioA && dtaInicioB <= dtaFimA)
                || (dtaFimB >= dtaInicioA && dtaFimB <= dtaFimA)
                || ((this.isTipoContratoNivelFamilia || this.isTipoContratoEpoca)
                  && acaoA.idsFamilias && acaoA.idsFamilias.length
                  && acaoB.idsFamilias && !acaoB.idsFamilias.length))) {
                if (!this.isTipoContratoNivelFamilia && acaoA.valorBonificacao && parseInt(acaoA.valorBonificacao, 10) > 0
                  && acaoB.valorBonificacao && parseInt(acaoB.valorBonificacao, 10) > 0) {
                  if (parseInt(acaoA.valorBonificacao, 10) === parseInt(acaoB.valorBonificacao, 10)) {
                    objResposta.validado = false;
                    objResposta.acoesDuplicadas.push({
                      categoria: categoriaA,
                      acao: acaoB,
                    });
                  }
                } else {
                  let qtdMetasIguais = 0;
                  /**
                   * Inicia loop das metas
                   */
                  for (let m = 0; m < acaoA.metas.length; m += 1) {
                    for (let n = 0; n < acaoB.metas.length; n += 1) {
                      const metaA = acaoA.metas[m];
                      const metaB = acaoB.metas[n];
                      if (metaA.vlrMetaDe === metaB.vlrMetaDe
                        && metaA.vlrMetaAte === metaB.vlrMetaAte
                        && metaA.vlrBonificacao === metaB.vlrBonificacao) {
                        qtdMetasIguais += 1;
                      }
                    }
                  }
                  /**
                  * Se a qtde de metas iguais for a mesma qtd de metas existentes, entao as acoes sao iguais
                  */
                  if (acaoA.metas.length > 0 && (qtdMetasIguais === acaoA.metas.length)) {
                    objResposta.validado = false;
                    objResposta.acoesDuplicadas.push({
                      categoria: categoriaA,
                      acao: acaoB,
                    });
                  }
                }
                if ((this.isTipoContratoNivelFamilia || this.isTipoContratoEpoca)
                  && ((!acaoA.idsFamilias || (acaoA.idsFamilias && !acaoA.idsFamilias.length))
                  && (!acaoB.idsFamilias || (acaoB.idsFamilias && !acaoB.idsFamilias.length)))) {
                  objResposta.validado = false;
                  objResposta.indDuplicadoComSemFamilia = true;
                  this.adicionaAcaoDuplicada(objResposta, categoriaA, a, indexList);
                  this.adicionaAcaoDuplicada(objResposta, categoriaA, b, indexList);
                }
                if ((this.isTipoContratoNivelFamilia || this.isTipoContratoEpoca)
                  && (((acaoA.idsFamilias && acaoA.idsFamilias.length)
                  && (!acaoB.idsFamilias || !acaoB.idsFamilias.length)) || ((acaoB.idsFamilias && acaoB.idsFamilias.length)
                  && (!acaoA.idsFamilias || !acaoA.idsFamilias.length)))) {
                  objResposta.validado = false;
                  objResposta.indDuplicadoComSemFamilia = true;
                  this.adicionaAcaoDuplicada(objResposta, categoriaA, a, indexList);
                  this.adicionaAcaoDuplicada(objResposta, categoriaA, b, indexList);
                }
                if ((this.isTipoContratoNivelFamilia || this.isTipoContratoEpoca)
                  && (acaoA.idsFamilias && acaoA.idsFamilias.length)
                  && (acaoB.idsFamilias && acaoB.idsFamilias.length)) {
                  const refName = this.retornaRefNameFamilia(categoriaA.id);
                  const nomesFamiliasConcatenados = acaoB.idsFamilias
                    .map((idB) => (this.listaFamilias[refName].find((f) => f.id === idB) || {}).nomeCategoria)
                    .filter((nome) => nome)
                    .join(', ');
                  acaoB.nomeFamiliaAgrupada = nomesFamiliasConcatenados;
                  const idFamiliaEmComum = acaoA.idsFamilias.some((id) => acaoB.idsFamilias.includes(id));
                  if (idFamiliaEmComum) {
                    if (acaoA.valorBonificacao && parseInt(acaoA.valorBonificacao, 10) > 0
                      && acaoB.valorBonificacao && parseInt(acaoB.valorBonificacao, 10) > 0) {
                      objResposta.validado = false;
                      this.adicionaAcaoDuplicada(objResposta, categoriaA, a, indexList);
                      this.adicionaAcaoDuplicada(objResposta, categoriaA, b, indexList);
                    } else {
                      let qtdMetasIguais = 0;
                      /**
                       * Inicia loop das metas
                       */
                      for (let m = 0; m < acaoA.metas.length; m += 1) {
                        for (let n = 0; n < acaoB.metas.length; n += 1) {
                          const metaA = acaoA.metas[m];
                          const metaB = acaoB.metas[n];
                          if (metaA.vlrMetaDe === metaB.vlrMetaDe
                            && metaA.vlrMetaAte === metaB.vlrMetaAte
                            && metaA.vlrBonificacao === metaB.vlrBonificacao) {
                            qtdMetasIguais += 1;
                          }
                        }
                      }
                      /**
                       * Se a qtde de metas iguais for a mesma qtd de metas existentes, entao as acoes sao iguais
                       */
                      if (qtdMetasIguais === acaoA.metas.length) {
                        objResposta.validado = false;
                        objResposta.acoesDuplicadas.push({
                          categoria: categoriaA,
                          acao: acaoB,
                        });
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      return objResposta;
    },
    adicionaAcaoDuplicada(objResposta, categoria, index, indexList) {
      if (!indexList.some((e) => e === index)) {
        indexList.push(index);
        const acao = categoria.acoes.at(index);
        if (acao.idsFamilias) {
          if (this.isTipoContratoEpoca) {
            const nomesFamiliasConcatenados = acao.idsFamilias
              .map((idB) => (this.listaFamilias.find((f) => f.id === idB) || {}).nomeCategoria)
              .filter((nome) => nome)
              .join(', ');
            acao.nomeFamiliaAgrupada = nomesFamiliasConcatenados;
          } else {
            const refName = this.retornaRefNameFamilia(categoria.id);
            const nomesFamiliasConcatenados = acao.idsFamilias
              .map((idB) => (this.listaFamilias[refName].find((f) => f.id === idB) || {}).nomeCategoria)
              .filter((nome) => nome)
              .join(', ');
            acao.nomeFamiliaAgrupada = nomesFamiliasConcatenados;
          }
        }
        objResposta.acoesDuplicadas.push({
          categoria,
          acao,
        });
      }
    },
    isArraysIguais(a, b) {
      a.sort();
      b.sort();
      return a.length === b.length && a.every((v, i) => v === b[i]);
    },
    verificaDuplicidadeContratoCopiado(acoesDuplicadas, acaoB) {
      // Verifica se há uma duplicata igual na lista
      const duplicataEncontrada = acoesDuplicadas.some((acaoDuplicada) => (
        acaoDuplicada.acao.dtaFim === acaoB.dtaFim
        && acaoDuplicada.acao.dtaInicio === acaoB.dtaInicio
        && acaoDuplicada.acao.idAcaoTipo === acaoB.idAcaoTipo
        && acaoDuplicada.acao.idCategoria === acaoB.idCategoria
        && acaoDuplicada.acao.nomeFamiliaAgrupada === acaoB.nomeFamiliaAgrupada
        && acaoDuplicada.acao.nomeTipoAcao === acaoB.nomeTipoAcao
        && acaoDuplicada.acao.valor === acaoB.valor
        && acaoDuplicada.acao.valorBonificacao === acaoB.valorBonificacao
        && acoesDuplicadas.index === acaoB.index
      ));
      if (duplicataEncontrada) {
        const conjuntoAcoes = new Set();
        const novaListaAcoesDuplicadas = [];

        acoesDuplicadas.forEach((acaoDuplicada) => {
          const chaveDuplicata = `${acaoDuplicada.acao.dtaFim}_${acaoDuplicada.acao.dtaInicio}
          _${acaoDuplicada.acao.idAcaoTipo}_${acaoDuplicada.acao.idCategoria}_${acaoDuplicada.acao.nomeFamiliaAgrupada}
          _${acaoDuplicada.acao.nomeTipoAcao}_${acaoDuplicada.acao.valor}_${acaoDuplicada.acao.valorBonificacao}`;
          if (!conjuntoAcoes.has(chaveDuplicata)) {
            conjuntoAcoes.add(chaveDuplicata);
            novaListaAcoesDuplicadas.push(acaoDuplicada);
          }
        });
        acoesDuplicadas.length = 0;
        Array.prototype.push.apply(acoesDuplicadas, novaListaAcoesDuplicadas);
      }
    },
    revalidaForms(categoria, acao) {
      // valida radio tipo de verba
      let formName = 'form-tipo-verba-'.concat(categoria.id)
        .concat('-').concat(acao.idAcaoTipo);
      let formEspecifico = this.$refs[formName];
      let formEspecificoValido = true;
      if (formEspecifico !== undefined) {
        for (let i = 0; i < formEspecifico.length; i += 1) {
          if (formEspecifico[i] !== undefined) {
            formEspecificoValido = formEspecifico[i].validate();
            if (formEspecificoValido) {
              formEspecifico[i].resetValidation();
            }
          }
        }
      }

      // valida radio periodicidade
      formName = 'form-periodicidade-'.concat(categoria.id)
        .concat('-').concat(acao.idAcaoTipo);
      formEspecifico = this.$refs[formName];
      if (formEspecifico !== undefined) {
        for (let i = 0; i < formEspecifico.length; i += 1) {
          if (formEspecifico[i] !== undefined) {
            formEspecificoValido = formEspecifico[i].validate();
            if (formEspecificoValido) {
              formEspecifico[i].resetValidation();
            }
          }
        }
      }

      if (this.isTipoContratoSamsung || this.isTipoContratoNivelFamilia) {
        // valida datas
        formName = 'form-periodo-negociacao-'.concat(categoria.id)
          .concat('-').concat(acao.idAcaoTipo);
        formEspecifico = this.$refs[formName];
        if (formEspecifico !== undefined) {
          for (let i = 0; i < formEspecifico.length; i += 1) {
            if (formEspecifico[i] !== undefined) {
              formEspecificoValido = formEspecifico[i].validate();
              if (formEspecificoValido) {
                formEspecifico[i].resetValidation();
              }
            }
          }
        }

        // valida calculo apuracao
        formName = 'form-calculo-apuracao-'.concat(categoria.id)
          .concat('-').concat(acao.idAcaoTipo);
        formEspecifico = this.$refs[formName];
        if (formEspecifico !== undefined) {
          for (let i = 0; i < formEspecifico.length; i += 1) {
            if (formEspecifico[i] !== undefined) {
              formEspecificoValido = formEspecifico[i].validate();
              if (formEspecificoValido) {
                formEspecifico[i].resetValidation();
              }
            }
          }
        }

        // valida calculo bonificacao
        formName = 'form-calculo-bonificacao-'.concat(categoria.id)
          .concat('-').concat(acao.idAcaoTipo);
        formEspecifico = this.$refs[formName];
        if (formEspecifico !== undefined) {
          for (let i = 0; i < formEspecifico.length; i += 1) {
            if (formEspecifico[i] !== undefined) {
              formEspecificoValido = formEspecifico[i].validate();
              if (formEspecificoValido) {
                formEspecifico[i].resetValidation();
              }
            }
          }
        }
      }
    },
    revalidaValor(categoria, acao) {
      // valida campo valor
      const formName = 'form-acao-categoria-valor-'.concat(categoria.id)
        .concat('-').concat(acao.idAcaoTipo);
      const formEspecifico = this.$refs[formName];
      let formEspecificoValido = true;
      if (formEspecifico !== undefined) {
        for (let i = 0; i < formEspecifico.length; i += 1) {
          if (formEspecifico[i] !== undefined) {
            formEspecificoValido = formEspecifico[i].validate();
            if (formEspecificoValido) {
              formEspecifico[i].resetValidation();
            }
          }
        }
      }
    },
    obterCategoriasPreenchidas() {
      return this.listaCategoriasCopia;
    },
    carregarConfiguracao(acao, categoria) {
      const { idAcaoTipo, id } = acao;

      const promise = id !== undefined
        ? this.configuracaoResource.buscarConfigSnapshot({ idAcao: id })
        : this.configuracaoResource.buscarConfigVigente({ idTipoAcao: idAcaoTipo });

      return promise.then((res) => {
        acao.configuracao = new AcaoConfiguracao(res.data);
        acao.isProdutoObrigatorio = acao.configuracao.configuracao.passo3.listaProduto.filter((el) => el.label === 'produto' && el.obrigatorio).length > 0;
        acao.isProdutoExcecao = acao.configuracao.configuracao.passo3.listaProduto.filter((el) => el.label === 'produto' && el.excecao).length > 0;
        acao.idConfiguracao = acao.configuracao.id;
        acao.idCategoria = categoria.id;
        if (!acao.id && !acao.metas) {
          acao.metas = [];
        }
        if (acao.configuracao.periodosApuracao && acao.configuracao.periodosApuracao.length === 1) {
          [acao.periodosApuracao] = acao.configuracao.periodosApuracao;
        }
        if (acao.configuracao.bonificacoes && acao.configuracao.bonificacoes.length === 1) {
          [acao.formaBonificacao] = acao.configuracao.bonificacoes;
        }
        if (this.isTipoContratoSamsung || this.isTipoContratoNivelFamilia || this.isTipoContratoEpoca) {
          if (acao.configuracao.calculosApuracao && acao.configuracao.calculosApuracao.length === 1) {
            [acao.calculoApuracao] = acao.configuracao.calculosApuracao;
            acao.calculoApuracaoAlterado = false;
          }
          this.setarCalculoApuracao(acao);
          if (acao.configuracao.calculosBonificacao && acao.configuracao.calculosBonificacao.length === 1) {
            [acao.calculoBonificacao] = acao.configuracao.calculosBonificacao;
          }
        }
        this.$forceUpdate();
      })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    acaoBonificacoes(acao) {
      if (!acao.configuracao) {
        return [];
      }
      return acao.configuracao.configuracao.passo5.apuracao.bonificacoes;
    },
    acaoPeriodos(acao) {
      if (!acao.configuracao) {
        return [];
      }
      return acao.configuracao.configuracao.passo5.apuracao.periodos;
    },
    acaoCalculosApuracao(acao) {
      if (!acao.configuracao) {
        return [];
      }
      return acao.configuracao.configuracao.passo5.apuracao.calculosApuracao;
    },
    acaoCalculosBonificacao(acao) {
      if (!acao.configuracao) {
        return [];
      }
      return acao.configuracao.configuracao.passo5.apuracao.calculosBonificacao;
    },
    copiarLinhaAcao(acao, acoesDaCategoriaPrincipal) {
      const indiceAcao = acoesDaCategoriaPrincipal.findIndex((item) => item.nomeAcaoTipo === acao.nomeAcaoTipo);
      let copiaAcaoPrincipal = null;
      acao.idsCategoriasAgrupadas.forEach((idCategoria) => {
        const copiaAcao = copyObject(acao);
        copiaAcao.idCategoria = idCategoria;
        const vlrIdsCopiaItens = [];
        acoesDaCategoriaPrincipal.forEach((item) => {
          if (item.idCopia && item.nomeAcaoTipo === acao.nomeAcaoTipo && item.idCategoria === idCategoria) {
            vlrIdsCopiaItens.push(item.idCopia);
          }
        });
        const maiorVlrIdCopia = Math.max(vlrIdsCopiaItens);
        copiaAcao.idCopia = maiorVlrIdCopia ? maiorVlrIdCopia + 1 : 1;
        delete copiaAcao.id;
        delete copiaAcao.idAcao;
        if (!copiaAcaoPrincipal) {
          /**
           * Se estiver fazendo a cópia da ação da categoria principal, já insere na lista passada como parâmetro
           */
          copiaAcaoPrincipal = copyObject(copiaAcao);
          acoesDaCategoriaPrincipal.splice(indiceAcao + 1, 0, copiaAcaoPrincipal);
        } else {
          /**
           * Se estiver fazendo a cópia da ação da categoria agrupada, encontra a categoria e insere a ação na lista dela
           */
          const categoriaAgrupada = this.listaCategorias.find((cat) => cat.id === idCategoria);
          categoriaAgrupada.acoes.splice(indiceAcao + 1, 0, copiaAcao);
        }
      });
    },
    removerCopia() {
      if (this.acaoRemoverSelecionada.id && this.acaoRemoverSelecionada.idCopia) {
        this.$emit('PlanejamentoContratoFormAcoesControle__RemoverAcao', this.acaoRemoverSelecionada.id);
      }
      const indiceAcao = this.categoriasAcoesRemover
        .findIndex((item) => item.idCopia === this.acaoRemoverSelecionada.idCopia
          && item.nomeAcaoTipo === this.acaoRemoverSelecionada.nomeAcaoTipo);
      this.categoriasAcoesRemover.splice(indiceAcao, 1);
    },
    removerCopiaConfirm(acao, itens) {
      this.acaoRemoverSelecionada = acao;
      this.categoriasAcoesRemover = itens;
      this.mensagemConfirmacao = this.$t('message.deseja_remover_acao_contrato');
      setTimeout(() => this.$refs.modalRemoverAcao.open());
    },
    abrirModalSelecaoProduto(acao, categoria) {
      this.montaObjetoContrato(acao, categoria);
      setTimeout(() => {
        this.$refs.modalSelecaoProduto.open();
      }, 350);
    },
    montaObjetoContrato(acao, categoria) {
      this.objetoPassoGeral = this.getObjetoOutroPasso('geral');
      this.acaoSelecionada = acao;
      this.categoriaSelecionada = categoria;
      this.dadosOrigem = {
        idFornecedor: this.objetoPassoGeral.fornecedor ? this.objetoPassoGeral.fornecedor.id : null,
        idGrupoFornecedor: this.idGrupoFornecedor,
        fornecedor: this.objetoPassoGeral.fornecedor,
      };
    },
    atualizarChave() {
      this.chave += 1;
    },
    isCadastro(acao) {
      if (!acao.status || acao.status === 'EM_CADASTRO') {
        return true;
      }
      return false;
    },
    valorCalculoApuracaoAlterado(acao) {
      if (acao.calculoApuracao) {
        if (acao.calculoApuracao === 'SELLIN_VOLUME' || acao.calculoApuracao === 'SELLOUT_VOLUME') {
          acao.calculoApuracaoVolume = true;
          acao.calculoApuracaoValor = false;
          acao.calculoApuracaoPercentual = false;
        } else if (acao.calculoApuracao === 'SELLIN_PERCENTUAL' || acao.calculoApuracao === 'SELLOUT_PERCENTUAL') {
          acao.calculoApuracaoVolume = false;
          acao.calculoApuracaoValor = false;
          acao.calculoApuracaoPercentual = true;
        } else if (acao.calculoApuracao === 'QUANTIDADE') {
          acao.calculoApuracaoVolume = true;
          acao.calculoApuracaoValor = false;
          acao.calculoApuracaoPercentual = false;
        } else {
          acao.calculoApuracaoVolume = false;
          acao.calculoApuracaoValor = true;
          acao.calculoApuracaoPercentual = false;
        }
        if (acao.metas && acao.metas.length) {
          acao.calculoApuracaoAlterado = true;
        }
      }
    },
    setarCalculoApuracao(acao) {
      if (acao.calculoApuracao) {
        if (acao.calculoApuracao === 'SELLIN_VOLUME' || acao.calculoApuracao === 'SELLOUT_VOLUME') {
          acao.calculoApuracaoVolume = true;
          acao.calculoApuracaoValor = false;
          acao.calculoApuracaoPercentual = false;
        } else if (acao.calculoApuracao === 'SELLIN_PERCENTUAL' || acao.calculoApuracao === 'SELLOUT_PERCENTUAL') {
          acao.calculoApuracaoVolume = false;
          acao.calculoApuracaoValor = false;
          acao.calculoApuracaoPercentual = true;
        } else if (acao.calculoApuracao === 'QUANTIDADE') {
          acao.calculoApuracaoVolume = true;
          acao.calculoApuracaoValor = false;
          acao.calculoApuracaoPercentual = false;
        } else {
          acao.calculoApuracaoVolume = false;
          acao.calculoApuracaoValor = true;
          acao.calculoApuracaoPercentual = false;
        }
      } else {
        acao.calculoApuracaoVolume = false;
        acao.calculoApuracaoValor = true;
        acao.calculoApuracaoPercentual = false;
      }
    },
    isMetaCumulativa(acao) {
      if (!acao.configuracao || !acao.configuracao.configuracao || !acao.configuracao.configuracao.passo5
       || !acao.configuracao.configuracao.passo5.apuracao || !acao.configuracao.configuracao.passo5.apuracao.meta) {
        return false;
      }
      return ['CUMULATIVA_COM_META_PARTIDA', 'CUMULATIVA_SEM_META_PARTIDA']
        .includes(acao.configuracao.configuracao.passo5.apuracao.meta);
    },
    isMetaNaoAplica(acao) {
      return this.isMetaCumulativa(acao) || !this.acaoCalculosApuracao(acao).length;
    },
    ativarAdicionarProdutos(acao) {
      return !this.isMetaCumulativa(acao) && (acao.isProdutoObrigatorio || acao.isProdutoExcecao);
    },
    exibirModalMetaIntervaloCumulativa(categoria, acao) {
      acao.calculoApuracaoAlterado = false;
      this.categoriaSelecionada = categoria;
      this.acaoSelecionada = acao;
      this.exibeModalMetaIntervaloCumulativa = true;
    },
    fecharModalMetaIntervaloCumulativa() {
      this.exibeModalMetaIntervaloCumulativa = false;
      this.revalidaForms(this.categoriaSelecionada, this.acaoSelecionada);
    },
    buscaFamilias(idCategoria, autocomplete = null) {
      const params = {};
      const refName = this.retornaRefNameFamilia(idCategoria);
      const autoCompletFamilia = this.$refs[refName];

      if (idCategoria) {
        params.id_categoria = idCategoria;
      }
      if (autocomplete && autocomplete.length) {
        params.autocomplete = autocomplete;
      }
      return this.planejamentoAcaoResource.buscarFamilia(params)
        .then((res) => {
          this.listaFamilias[refName] = res.data;
          if (autoCompletFamilia) {
            for (let i = 0; i < autoCompletFamilia.length; i += 1) {
              if (autoCompletFamilia[i] !== undefined) {
                autoCompletFamilia[i].cachedItems = res.data;
              }
            }
          }
        });
    },
    buscaFamiliasEpoca(idCategoria, autocomplete = null) {
      const refName = this.retornaRefNameFamilia(idCategoria);
      const autoCompletFamilia = this.$refs[refName];
      const params = {};

      if (this.objetoPassoGeral.fornecedor && this.objetoPassoGeral.fornecedor.id) {
        params.id_fornecedor = this.objetoPassoGeral.fornecedor.id;
      }
      if (this.idGrupoFornecedor) {
        params.id_grupo_fornecedor = this.idGrupoFornecedor;
      }

      if (autocomplete && autocomplete.length) {
        params.autocomplete = autocomplete;
      }
      return this.planejamentoAcaoResource.buscarFamiliaEpoca(params)
        .then((res) => {
          this.listaFamilias[refName] = res.data;
          if (autoCompletFamilia) {
            for (let i = 0; i < autoCompletFamilia.length; i += 1) {
              if (autoCompletFamilia[i] !== undefined) {
                autoCompletFamilia[i].cachedItems = res.data;
              }
            }
          }
        });
    },
    filtroFamilia(item, queryText, itemText) {
      if (!item) return null;
      const codFamilia = item.codNomeFamilia.toLowerCase();
      const searchText = queryText.toLowerCase();
      return codFamilia.indexOf(searchText) > -1
        || itemText.indexOf(searchText) > -1;
    },
    retornaRefNameFamilia(idCategoria) {
      return 'verba_variavel_familia-'.concat(idCategoria);
    },
  },
  mounted() {
    setTimeout(this.setCategorias, 500);
    setTimeout(() => {
      this.iniciandoPainel = false;
    }, 750);
    if (this.isTipoContratoEpoca) {
      this.objetoPassoGeral = this.getObjetoOutroPasso('geral');
    }
    if ((this.isTipoContratoNivelFamilia || this.isTipoContratoEpoca) && this.contratoEdicao && this.contratoEdicao.passoAcoes
      && this.contratoEdicao.passoAcoes.acoes) {
      this.contratoEdicao.passoAcoes.acoes.forEach((acao) => {
        if (acao.idsFamilias && acao.idsFamilias.length) {
          const refName = this.retornaRefNameFamilia(acao.idCategoria);
          if (this.isTipoContratoEpoca) {
            Promise.all([this.buscaFamiliasEpoca(acao.idCategoria)]).then(() => {
              acao.idsFamilias = this.listaFamilias[refName].filter((familia) => acao.idsFamilias.includes(familia.id));
            });
          } else {
            Promise.all([this.buscaFamilias(acao.idCategoria)]).then(() => {
              acao.idsFamilias = this.listaFamilias[refName].filter((familia) => acao.idsFamilias.includes(familia.id));
            });
          }
        }
      });
    }
  },
};
</script>
<style>
.Container_Tabela table.v-table tbody td:first-child, .Container_Tabela table.v-table tbody td:not(:first-child),
.Container_Tabela table.v-table tbody th:first-child, .Container_Tabela table.v-table tbody th:not(:first-child),
.Container_Tabela table.v-table thead td:first-child, .Container_Tabela table.v-table thead td:not(:first-child),
.Container_Tabela table.v-table thead th:first-child, .Container_Tabela table.v-table thead th:not(:first-child) {
  padding: 0 11px;
}
.select--sem--linha > .v-input__control > .v-input__slot:before {
  width: 0% !important;
}
.select--sem--linha > .v-input__control > .v-input__slot:after {
  border-style: none;
}
#dialog_acao_categoria_valor_intervalo .v-dialog .v-dialog--active {
  height: 50%;
}
</style>
